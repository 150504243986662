@use "styles/globals.scss" as *;
.textBlock {
  &:first-child:last-child {
    @include gt-mobile {
      height: 100%;
    }
  }

  &.fullColumn {
    position: relative;
  }

  h1 {
    @include fontBodyXL;
  }

  h4 {
    @include fontBodyMedium;
  }

  h5 {
    @include fontBodySmall;
  }

  p:not([class]),
  ul,
  ol {
    @include fontBodyLarge;
  }

  ul,
  ol {
    text-align: left;
    padding: 0 0 0 25px;

    @include mobile {
      padding-left: 24px;
    }

    li {
      margin-bottom: 20px;
    }
  }

  figure {
    margin: 36px 0;

    iframe {
      max-width: 100%;
    }
  }
}
