@use "styles/globals.scss" as *;
.imageBlock {
  position: relative;
  margin: $margin * 2 0;

  @include mobile {
    margin: $margin 0;
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto 5px;
  }
}
