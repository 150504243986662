$font: 'Avenir Next W01', 'Open Sans', Helvetica, Arial, sans-serif;
$fontSize: 14px;
$small: 0.85rem;
$demiBold: 700;
$heavy: 900;
$medium: 600;

$h1Size: 2.57rem; // 36px
$h2Size: 1.71rem; // 29px;
$headingSize: 1.35rem; // 19px
$titleSize: 1.15rem; // 16px;

$containerWidth: 1200px;
$largeContainerWidth: 1440px;
$containerPadding: 10px;
$standardPadding: 20px;
$margin: 24px;

$inputHeight: 50px;
$inputPadding: 20px;

$borderColor: #d6d6d6;
$borderRadius: 5px;

// autocomplete component
$autocompleteBg: white;
$autocompleteHoverBg: #f0f4f7;

// site
$gradientBg: linear-gradient(43deg, #ffffff00 0%, #3f414638 100%);

$outlineColor: #f0f0f0;
$sidebarDivider: #e6e6e6;
$tagSeparated: #979797;

$lightBorderColor: #e7e7e7;
$lightFillColor: #fafafa;
$blueFillColor: #f4fbff;
$blueBorderColor: #bcddf0;

$linkColor: #1192ff;
$vulcan: #10151f;
$darkBg: #1c1f22;
$dark1D: #1d1f22;
$dark37: #37393e;
$shark: #252b30;
$grey: #404040;
$outerSpace: #333b42;
$lightGrey: #637381;
$lighterGrey: #d7dbe0;
$ghost: #bdc3cf;
$green: #33b460;
$brightGreen: #20de62;
$blue: #248cc8;
$darkBlue: #0d2660;
$ebonyClay: #222939;
$red: #d32635;
$orange: #ffbb00;

$placeholderColor: #d7d7d7;
$disableColor: #8ea0b0;

// Redesign variables (/OpenPay)
$buttonBlue: $linkColor;
$buttonBlueHover: #278fea;
$sectionGreyBg: #f4f6f8;
$sectionBlueBg: #ecf6ff;

// site elements
$membersBarHeight: 24px;
$desktopNavHeight: 75px;
$mobileNavHeight: 80px;

$collapsedMenuHeight: 50px;

// components
$switchButtonHeight: 36px;
$switchButtonRadius: 18px;

// Cars page
$sidebarBg: #fbfcfd;
$sidebarBorder: #e9edf4;
$sidebarWidth: 340px; // 40px padding

// CarsView page
$sidebarGrey: #202229;
$carDetailBg: rgba(#89a0b2, 0.5);

$filterBarHeight: 62px;
$filterItemHeight: 28px;

$filterMobile: 55px;
$filterMobileList: 30px;
$filterMobileButton: 55px;

$mobileFooterHeight: 718;
$desktopFooterHeight: 362;

$formBgColor: #e0e5e9;
$dividerGrey: #d5dde1;

// mymoto overrides
$colorPrimaryHighlight: #166be1;
$colorSecondaryLightLightBlue: #ebf3ff;

// Available car colours
$carColors: (
  'grey': #b7b7b7,
  'blue': #242df2,
  'white': #ffffff,
  'silver': #c0c0c0,
  'red': #e90b33,
  'black': #000000,
  'orange': #ff440a,
  'green': #19b000,
  'brown': #964b00,
  'yellow': #ffd600,
  'purple': #6e0ee9,
  'pink': #f513ec,
);

$gradient: linear-gradient(
  -30deg,
  rgba(#e8495f, 0.1) 0%,
  rgba(#f48686, 0.1) 25%,
  rgba(#f5b882, 0.1) 65%,
  rgba(#f5b982, 0.1) 100%
);

$opacity: 0.1;

$gradientFull: linear-gradient(
  120deg,
  rgba(#e8495f, 1) 0%,
  rgba(#e94a60, 1) 20%,
  rgba(#f48686, 1) 65%,
  rgba(#f5b882, 1) 75%,
  rgba(#f5b982, 1) 100%
);

$font: 'Kumbh Sans';
$fontSize: 14px;

$lightRed: #e8495f;
$darkBlue: #2a363b;
$margin: 24px;
$lightGrey: #fbf9fd;

$maxContainerWidth: 1600px;
$maxContentWidth: 1320px;
$minFooterHeight: 68px; // landing page
$minHeaderHeight: 360px;

$spacer: $margin;
$gridGutterWidth: $margin;

$borderRadiusField: 8px;

$spacers: (
  0: 0,
  1: $spacer * 0.5,
  2: $spacer * 1,
  3: $spacer * 1.5,
  4: $spacer * 2,
  5: $spacer * 2.5,
  6: $spacer * 3,
);

/* Theming */
// https://codepen.io/jakealbaugh/post/css4-variables-with-fallbacks-using-sass

// set some defaults, will be overwritten by cms :root with !important
$colour: (
  primary-accent: $colorPrimaryAccent,
  primary-highlight: $colorPrimaryHighlight,
  primary-middle: $colorPrimaryMiddle,
  primary-dark: $colorPrimaryDark,
  secondary-page-bg: $colorSecondaryPageBg,
  secondary-ui-middle: $colorSecondaryUiMiddle,
  tertiary-error: $colorTertiaryError,
  tertiary-white: $colorTertiaryWhite,
  tertiary-pricesavings: $colorTertiaryPricesavings,
  tertiary-accent: $colorTertiaryAccent,
);

$varMaps: (
  colour: $colour,
);

$props: (
  color: colour,
  background: colour,
  background-color: colour,
  fill: colour,
  stroke: colour,
  border-top-color: colour,
  border-bottom-color: colour,
  border-bottom: colour,
  border-left-color: colour,
  border-color: colour,
);
