@use "styles/globals.scss" as *;
.miniHero {
  display: flex;
  position: relative;
  padding-top: 79px;
  padding-bottom: $margin * 2;
  text-align: center;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mobile {
    padding: $margin 0;
  }

  @include cssVar(color, tertiary-white);
  @include cssVar(background-color, primary-accent);

  &.noSubheading {
    padding-bottom: $margin;
  }

  .body {
    position: relative;
    z-index: 1;

    h1 {
      @include fontHeadingLarge4;

      @include mobile {
        @include fontHeadingXL;
      }
    }

    p {
      margin: 17px 0 0;

      @include fontBodyXL;

      @include mobile {
        @include fontBodyLarge;
      }
    }
  }

  .pattern {
    position: absolute;
    width: 100%;
    max-width: $maxContainerWidth;
    box-shadow: 0 0 120px #54626547;

    svg {
      width: 100%;
      transform: scaleX(-1);

      g rect {
        &:first-child {
          @include cssVar(fill, primary-accent);
        }
      }
    }
  }
}
