@use "styles/globals.scss" as *;
$accordionHeight: 71px;
$accordionHeightMobile: 50px;

.accordion {
  transition: all 150ms ease-out;
  list-style-type: none;

  > .accordionItemWrapper.accordionItemWrapper {
    margin: 0 $margin;
    border-bottom: 1px solid;

    @include cssVar(border-bottom-color, secondary-ui-middle);

    > .accordionItemHeader {
      display: flex;
      width: 100%;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      text-align: left;

      @include mobile {
        padding: 0 5px;
      }

      &:focus {
        outline: none;
      }

      h3 {
        width: 90%;
        margin: 0;
        padding: $margin 0;
        font-weight: bold;

        @include fontHeadingMedium;

        @include mobile {
          padding: ($accordionHeightMobile - $margin) / 2 0;
          line-height: $margin;
        }
      }
    }

    .accordionItemBody.accordionItemBody {
      width: 90%;
      flex-flow: column wrap;
      text-align: left;
      padding-bottom: $margin;

      p {
        margin: 0;
      }

      > div {
        p,
        ul {
          @include fontBodyMedium;

          line-height: 24px;

          li {
            margin-bottom: 5px;
          }
        }

        a {
          text-decoration: underline;
        }
      }
    }
  }

  .accordionButton {
    transition: 300ms ease all;

    svg {
      overflow: visible;

      path {
        @include cssVar(fill, primary-accent);
      }
    }

    &:focus {
      outline: none;
    }
  }

  &.active {
    .accordionItemBody {
      display: flex;
    }
  }
}
