@use "styles/globals.scss" as *;
.row {
  @include mobile {
    background-image: none !important; // stylelint-disable-line declaration-no-important
  }

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.withBackground {
    min-height: 645px;

    @include mobile {
      min-height: auto;
    }
  }
}
