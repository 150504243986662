@use "styles/globals.scss" as *;
.newsArticle {
  display: block;

  .pageContent {
    margin: auto;
    max-width: 873px;
    margin-top: $margin;
  }
}
