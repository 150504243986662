// Exported from Figma using CSSGen Plugin by Microsoft 4/1/2021
$colorPrimaryAccent: #0062ea;
$colorPrimaryHighlight: #ec008c;
$colorPrimaryMiddle: #546265;
$colorPrimaryDark: #231f20;
$colorSecondaryPageBg: #fff;
$colorSecondaryUiMiddle: #ced7d9;
$colorTertiaryError: #ff002e;
$colorTertiaryWhite: #fff;
$colorTertiaryPricesavings: #00c259;
$colorTertiaryAccent: #ffa800;

@mixin fontLinkSmall {
  font-size: 1rem;
  font-weight: bold;
}

@mixin fontLinkMedium {
  font-size: 1.1429rem;
  font-weight: bold;
  line-height: 24px;
}

@mixin fontHeadingLarge {
  font-size: 1.7143rem;
  font-weight: bold;
  line-height: 30px;
}

@mixin fontHeadingMedium2 {
  font-size: 1.2857rem;
  font-weight: bold;
  line-height: 24px;
}

@mixin fontBodyMedium {
  font-size: 1.1429rem;
  font-weight: normal;
  line-height: 24px;
}

@mixin fontBodyXL {
  font-size: 1.7143rem;
  font-weight: normal;
  line-height: 36px;
}

@mixin fontHeadingLarge4 {
  font-size: 3.4286rem;
  font-weight: bold;
  line-height: 60px;
}

@mixin fontHeadingSmall {
  font-size: 1.1429rem;
  font-weight: bold;
}

@mixin fontBodyXS {
  font-size: 0.8571rem;
  font-weight: normal;
  line-height: 18px;
}

@mixin fontBodySmall {
  font-size: 1rem;
  font-weight: normal;
  line-height: 20px;
}

@mixin fontLinkSmall2 {
  font-size: 0.8571rem;
  font-weight: bold;
}

@mixin fontLinkLarge {
  font-size: 1.2857rem;
  font-weight: bold;
  line-height: 24px;
}

@mixin fontHeadingXXL {
  font-size: 2.5714rem;
  font-weight: bold;
  line-height: 42px;
}

@mixin fontHeadingMedium {
  font-size: 1.4286rem;
  font-weight: bold;
  line-height: 32px;
}

@mixin fontBodyMediumStrikethrough {
  font-size: 1.1429rem;
  font-weight: normal;
  line-height: 24px;
}

@mixin fontBodyLarge {
  font-size: 1.2857rem;
  font-weight: normal;
  line-height: 30px;
}

@mixin fontBodyLargeBold {
  font-size: 1.2857rem;
  font-weight: bold;
  line-height: 30px;
}

@mixin fontLabelSmall {
  font-size: 1rem;
  font-weight: normal;
}

@mixin fontBodySmallBold {
  font-size: 1rem;
  font-weight: bold;
  line-height: 20px;
}

@mixin fontLinkLarge2 {
  font-size: 1.4286rem;
  font-weight: bold;
  line-height: 24px;
}

@mixin fontBodyLarge2Strikethrough {
  font-size: 1.7143rem;
  font-weight: normal;
  line-height: 36px;
}

@mixin fontBodyLargeStrikethrough {
  font-size: 1.2857rem;
  font-weight: normal;
  line-height: 30px;
}

@mixin fontHeadingXL {
  font-size: 2.1429rem;
  font-weight: bold;
  line-height: 42px;
}

@mixin fontHeadingXS {
  font-size: 1rem;
  font-weight: bold;
}

@mixin fontBodyMediumBold {
  font-size: 1.1429rem;
  font-weight: bold;
  line-height: 24px;
}
