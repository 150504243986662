/* stylelint-disable scss/no-global-function-names */
// the variable mixin takes a property and variable name
// and an optional override to hide the fallback
@mixin cssVar($prop, $var, $show-fall: true) {
  // get the property's map name(s)
  $mapName: map-get($props, $prop);
  $nestName: null;
  $nestMapName: null;
  $map: null;
  $varFall: null;
  $varOutput: null;

  // if a list, we need to go deeper
  @if type-of($mapName) == list {
    $nestName: list.nth($mapName, 1);
    $nestMapName: list.nth($mapName, 2);
  }

  // if it is a nested map
  @if $nestName {
    // get the map from nested map-name
    $map: map-get($varMaps, $nestName);

    // get the nested map
    $nestMap: map-get($map, $nestMapName);

    // fallback value, get the var value from the nested map
    $varFall: map-get($nestMap, $var);

    // our css4 variable output
    $varOutput: var(--#{$nestName}-#{$nestMapName}-#{$var});
  } @else {
    // get the map from map name
    $map: map-get($varMaps, $mapName);

    // fallback value, grab the variable's value from the map
    $varFall: map-get($map, $var);

    // our css4 variable output
    $varOutput: var(--#{$mapName}-#{$var});
  }

  // if show fallback is not overridden to be null
  @if $show-fall {
    #{$prop}: $varFall;
  }

  // css4 variable output
  #{$prop}: $varOutput;
}

@mixin dropShadow($offsetX: 0, $offsetY: 0, $blurRadius: 16px, $spreadRadius: 0, $opacity: 0.15) {
  box-shadow: $offsetX $offsetY $blurRadius $spreadRadius rgba($colorPrimaryMiddle, $opacity);
}

@mixin maxContentWidth {
  max-width: $maxContentWidth;

  @media screen and (max-width: $maxContentWidth - 1) {
    padding: $margin;
    max-width: calc(#{$maxContentWidth} + #{$margin});
  }
}

@mixin overflowSlider {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  grid-auto-flow: column;
  scroll-behavior: smooth;
  justify-content: flex-start;
  grid-template-columns: unset;

  &::-webkit-scrollbar-thumb {
    background: transparent !important; // stylelint-disable-line
  }
}

// Hover with accent color given a prop
// @include hoverAccent(border-color)
@mixin hoverAccent($prop) {
  transition: 0.2s all ease-in-out;

  &:hover {
    @include cssVar($prop, primary-highlight);
  }
}
